.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.personal_page {
  height: 100vh;
  background: #fff;
  /*手机号码*/
}
.personal_page .fdc {
  margin-top: 0.2rem;
}
.personal_page header {
  font-size: 0.24rem;
  color: #666;
  margin-bottom: 0.78rem;
}
.personal_page header .upload-box {
  position: relative;
}
.personal_page header .upload-box input {
  position: absolute;
  width: 1.56rem;
  height: 1.9rem;
  right: 0;
  top: 0;
  opacity: 0;
}
.personal_page header .upload-box .head-img {
  width: 1.56rem;
  height: 1.56rem;
  border-radius: 50%;
}
.personal_page .cell {
  width: 100%;
  height: 0.88rem;
  line-height: 0.88rem;
  margin-left: 0.24rem;
  border-bottom: 1px solid #D8D8D8;
  overflow: hidden;
}
.personal_page .cell span {
  font-size: 0.32rem;
  color: #000;
  width: 1.28rem;
  display: inline-block;
}
.personal_page .cell input {
  height: 0.88rem;
  font-size: 0.32rem;
  color: #666;
}
.personal_page .yd-cell-left {
  width: 1.28rem;
  font-size: 0.32rem;
  color: #000;
  line-height: 0.88rem;
}
.personal_page .yd-cell-right {
  height: 0.88rem;
  width: 70%;
}
.personal_page .yd-cell-right span {
  font-size: 0.3rem;
  color: #999;
}
.personal_page .yd-cell {
  overflow: hidden;
}
.personal_page .yd-cell:after {
  margin-left: 0.24rem;
}
.personal_page .yd-cell-right input[type=text] {
  font-size: 0.32rem;
  color: #666;
}
.personal_page input:disabled {
  font-size: 0.32rem;
  color: #999;
}
.personal_page .save-box {
  width: 100%;
  height: 0.88rem;
  padding: 0 0.24rem;
  margin-top: 1.58rem;
}
.personal_page .save-btn {
  width: 100%;
  height: 100%;
  background: var(--main-color);
  border-radius: 1rem;
  line-height: 0.88rem;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
}
